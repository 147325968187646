<!-- 管理页面列表 -->
<template>
  <section>
    <template v-if="tableData">
      <el-input class="filterinput" v-model="filterTable" placeholder="输入关键字搜索" />
      <el-button @click="removeNode">
        <template v-if="type==='selectnodes'">移除</template>
        <template v-else>添加</template>
      </el-button>
      <el-button @click="clearNode">清除选择</el-button>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="节点id" width="120"></el-table-column>
        <el-table-column prop="text" label="节点名称"></el-table-column>
        <el-table-column prop="source" label="数据来源" width="180"></el-table-column>
      </el-table>
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        @prev-click="pageChange"
        @next-click="pageChange"
        :current-page.sync="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="pagesize"
        :total="pagetotal"
        background
      ></el-pagination>
    </template>
  </section>
</template>

<script>
export default {
  data() {
    return {
      filterTable: "",
      allTableData: [],
      tableData: [],
      pagetotal: 0,
      pagesize: 10,
      currentPage: 1,
      multipleSelection: []
    };
  },
  props: ["type"],
  watch: {
    filterTable() {
      this.currentPage = 1;
      this.pageTableData();
    }
  },
  methods: {
    initTable() {
      this.currentPage = 1;
      this.pagetotal = this.allTableData.length;
      this.pageTableData();
    },
    pageTableData() {
      let filterTable = this.filterTable,
        pagesize = this.pagesize,
        currentPage = this.currentPage,
        allTableData = this.allTableData;
      if (filterTable) {
        filterTable = filterTable.toUpperCase();
        allTableData = allTableData.filter(data => {
          return (
            data.text.indexOf(filterTable) !== -1 ||
            data.source.indexOf(filterTable) !== -1 ||
            data.id.indexOf(filterTable) !== -1
          );
        });
        this.pagetotal = allTableData.length;
      }
      this.tableData = allTableData.slice(
        pagesize * (currentPage - 1),
        pagesize * currentPage
      );
    },
    sizeChange(val) {
      this.pagesize = val;
      this.pageTableData();
    },
    pageChange(val) {
      this.currentPage = val;
      this.pageTableData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    clearNode() {
      this.$refs.multipleTable.clearSelection();
    },
    removeNode() {
      this.$emit("selectNodeChange", this.type, this.multipleSelection);
    }
  }
};
</script>

<style scoped>
.filterinput {
  width: 200px;
  margin-bottom: 10px;
}
</style>