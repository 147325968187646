export const modularData = [
    {
        name:'基本信息',
        comName:'basic',
        childCom:[
            {
                name:'智能信用评分',
                comName:'basic-score',
                iconImg:require('@/assets/images/credit/basic/对账管理@2x.png')
            },
            {
                name:'基本信息',
                comName:'basic-information',
                iconImg:require('@/assets/images/credit/basic/基本信息@2x.png')
            },
            {
                name:'交易信息',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/basic/2-采购交易信息@2x.png')
            },
            {
                name:'主体资质',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/basic/企业资质@2x.png')
            },
            {
                name:'财务概览',
                isTable:'1',
                righthandle:'进入详细财务数据',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/basic/财务概览@2x.png')
            },
            {
                name:'附件资料',
                comName:'basic-file',
                iconImg:require('@/assets/images/credit/basic/附件@2x.png')
            }
        ]
    },
    {
        name:'销售信息',
        comName:'sale',
        childCom:[
            {
                name:'销售基本信息',
                comName:'sale-information',
                iconImg:require('@/assets/images/credit/sale/基本信息@2x.png')
            },
            {
                name:'主体资质',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/sale/资质@2x.png')
            },
            {
                name:'交易情况',
                childName:'(当前未完成-全部)',
                comName:'sale-jyAll',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            },
            {
                name:'交易情况',
                childName:'(当前未完成-赊销部分)',
                comName:'sale-jywwc',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            },
            {
                name:'已结清订单',
                isTable:'1',
                righthandle:'进入详细',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/sale/完成订单数_画板1@2x.png')
            },
            {
                name:'风险提示记录',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/sale/风险提示报表@2x.png')
            },
            {
                name:'附件资料',
                comName:'credit-table',
                isTable:'1',
                iconImg:require('@/assets/images/credit/sale/附件@2x.png')
            }
        ]
    },
    {
        name:'采购信息',
        comName:'purchase',
        childCom:[
            {
                name:'采购基本信息',
                comName:'purchase-information',
                iconImg:require('@/assets/images/credit/purchase/采购 (1)@2x.png')
            },
            {
                name:'银行评级',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/purchase/银行@2x.png')
            },
            {
                name:'主体资质',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/purchase/采购 (1)@2x.png')
            },
            {
                name:'采购产品',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/purchase/采购@2x.png')
            },
            {
                name:'投标记录',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/purchase/投标@2x.png')
            },
            {
                name:'采购合同',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/purchase/采购合同 (1)@2x.png')
            },
            {
                name:'违规记录',
                comName:'credit-table',
                isTable:'1',
                iconImg:require('@/assets/images/credit/purchase/采购 (1)@2x.png')
            },
            {
                name:'同业违规',
                comName:'credit-table',
                isTable:'1',
                iconImg:require('@/assets/images/credit/purchase/88.违规记录@2x.png')
            },
            {
                name:'附件下载',
                comName:'credit-table',
                isTable:'1',
                iconImg:require('@/assets/images/credit/sale/附件@2x.png')
            }
        ]
    },
    {
        name:'同业评价',
        comName:'evaluate',
        childCom:[
            {
                name:'采购商认证',
                comName:'evaluate-authentication',
                iconImg:require('@/assets/images/credit/sale/基本信息@2x.png')
            },
            {
                name:'采购商履约动态考评',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            },
            {
                name:'供应商认证',
                comName:'evaluate-authentication',
                iconImg:require('@/assets/images/credit/sale/基本信息@2x.png')
            },
            {
                name:'供应商市场表现',
                childName:'(该项目采购商无)',
                comName:'evaluate-market',
                iconImg:require('@/assets/images/credit/sale/资质@2x.png')
            }, 
            {
                name:'供应商产品质量评价',
                childName:'(该项目采购商无)',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            },
            {
                name:'供应商履约动态考评',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            }
        ]
    },
    {
        name:'杜邦专家模型',
        comName:'dupont',
        childCom:[
            {
                name:'杜邦专家评分',
                comName:'dupont-score',
                iconImg:require('@/assets/images/credit/basic/对账管理@2x.png')
            },
            {
                name:'基本信息',
                comName:'dupont-information',
                iconImg:require('@/assets/images/credit/sale/基本信息@2x.png')
            }, 
            {
                name:'主体运营情况',
                comName:'dupont-operate',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            },
            {
                name:'上一年度前5大销售客户',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            },
            {
                name:'上一年度前5大上游供应商',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            },
            {
                name:'人力资源信息',
                comName:'dupont-humanres',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            },
            {
                name:'核心人员信息',
                comName:'dupont-core',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            },
            {
                name:'附件资料',
                isTable:'1',
                comName:'credit-table',
                iconImg:require('@/assets/images/credit/sale/交易@2x.png')
            }
        ]
    }
]