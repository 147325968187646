<template> 
    <div class="M2-M3-jjhj">
        <div class="M2-M3-content">

            <div class="M2-M3-jjhj-chart">
                <div class="M2-M3-jjhj-title">公司业务情况介绍</div>
                
                <div class="M2-M3-jjhj-entBrief">
                    公司主要从事的相关业务、盈利模式、主要产品和服务简介
                </div>

                <div class="M2-M3-jjhj-title" style="margin-top:20px;">主体运营</div>
                <div class="tableBox">
                    <table class="re_search_detail_table">
                        <thead>
                            <tr>  
                            <td width="10%;" rowspan="2" class="detailtitle">序号</td>
                            <td width="20%;" rowspan="2" class="detailtitle">项目/产品名称</td>
                            <td width="20%;" rowspan="2" class="detailtitle">项目/产品简介</td>
                            <td width="50%;" colspan="2" rowspan="1" class="detailtitle">
                                2020年
                            </td>
                            </tr>
                            <tr> 
                            <td width="25%;" class="detailtitle">收入占比</td>
                            <td width="25%;" class="detailtitle">利润占比</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr> 
                            <td width="10%;" >{{ztyytable.sn1}}</td>
                            <td width="20%;" >{{ztyytable.pojname1}}</td>
                            <td width="20%;" ></td>
                            <td width="25%;" >{{ztyytable.srmoney12020}}</td>
                            <td width="25%;" >{{ztyytable.lrzb12020}}</td>
                            </tr>
                            <tr> 
                            <td width="50%;" colspan="3">合计</td>
                            <td width="25%;" >{{ztyytable.srmoney12020}}</td>
                            <td width="25%;" >{{ztyytable.lrzb12020}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="M2-M3-jjhj-title" style="margin-top:20px;">上一年度前5大销售</div> 
                <table class="re_search_detail_table">
                    <thead>
                    <tr>
                        <td class="detailtitle" width="10%;">序号</td>
                        <td class="detailtitle" width="20%;">销售客户名称</td>
                        <td class="detailtitle" width="10%;">产品/服务名称</td>
                        <td class="detailtitle" width="10%;">销售额（万元）</td>
                        <td class="detailtitle" width="10%;">销售占比</td>
                        <td class="detailtitle" width="10%;">销售地域</td>
                        <td class="detailtitle" width="10%;">结算方式</td>
                        <td class="detailtitle" width="10%;">合作年限</td>
                        <td class="detailtitle" width="10%;">历史交易总额</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td width="10%;">{{wdxstable.sn1}}</td>
                        <td width="20%;">{{wdxstable.xskhmc1}}</td>
                        <td width="10%;">{{wdxstable.cpmc1}}</td>
                        <td width="10%;">{{wdxstable.xse1}}</td>
                        <td width="10%;">{{wdxstable.xszb1}}</td>
                        <td width="10%;">{{wdxstable.xsdy1}}</td>
                        <td width="10%;">{{wdxstable.jsfs1}}</td>
                        <td width="10%;">{{wdxstable.hznx1}}</td>
                        <td width="10%;">{{wdxstable.lsjyze1}}</td>
                    </tr>
                    <tr>
                        <td width="10%;">{{wdxstable.sn2}}</td>
                        <td width="20%;">{{wdxstable.xskhmc2}}</td>
                        <td width="10%;">{{wdxstable.cpmc2}}</td>
                        <td width="10%;">{{wdxstable.xse2}}</td>
                        <td width="10%;">{{wdxstable.xszb2}}</td>
                        <td width="10%;">{{wdxstable.xsdy2}}</td>
                        <td width="10%;">{{wdxstable.jsfs2}}</td>
                        <td width="10%;">{{wdxstable.hznx2}}</td>
                        <td width="10%;">{{wdxstable.lsjyze2}}</td>
                    </tr>
                    <tr>
                        <td width="10%;">{{wdxstable.sn3}}</td>
                        <td width="20%;">{{wdxstable.xskhmc3}}</td>
                        <td width="10%;">{{wdxstable.cpmc3}}</td>
                        <td width="10%;">{{wdxstable.xse3}}</td>
                        <td width="10%;">{{wdxstable.xszb3}}</td>
                        <td width="10%;">{{wdxstable.xsdy3}}</td>
                        <td width="10%;">{{wdxstable.jsfs3}}</td>
                        <td width="10%;">{{wdxstable.hznx3}}</td>
                        <td width="10%;">{{wdxstable.lsjyze3}}</td>
                    </tr>
                    <tr>
                        <td width="10%;">{{wdxstable.sn4}}</td>
                        <td width="20%;">{{wdxstable.xskhmc4}}</td>
                        <td width="10%;">{{wdxstable.cpmc4}}</td>
                        <td width="10%;">{{wdxstable.xse4}}</td>
                        <td width="10%;">{{wdxstable.xszb4}}</td>
                        <td width="10%;">{{wdxstable.xsdy4}}</td>
                        <td width="10%;">{{wdxstable.jsfs4}}</td>
                        <td width="10%;">{{wdxstable.hznx4}}</td>
                        <td width="10%;">{{wdxstable.lsjyze4}}</td>
                    </tr>
                    <tr>
                        <td width="10%;">{{wdxstable.sn5}}</td>
                        <td width="20%;">{{wdxstable.xskhmc5}}</td>
                        <td width="10%;">{{wdxstable.cpmc5}}</td>
                        <td width="10%;">{{wdxstable.xse5}}</td>
                        <td width="10%;">{{wdxstable.xszb5}}</td>
                        <td width="10%;">{{wdxstable.xsdy5}}</td>
                        <td width="10%;">{{wdxstable.jsfs5}}</td>
                        <td width="10%;">{{wdxstable.hznx5}}</td>
                        <td width="10%;">{{wdxstable.lsjyze5}}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="M2-M3-jjhj-title" style="margin-top:20px;">上一年度前5大上游供</div> 
                <table class="re_search_detail_table">
                    <thead>
                    <tr>
                        <td class="detailtitle" width="10%;">序号</td>
                        <td class="detailtitle" width="30%;">上游供应商名称</td>
                        <td class="detailtitle" width="10%;">商品/服务名称</td>
                        <td class="detailtitle" width="10%;">采购额（万元）</td>
                        <td class="detailtitle" width="10%;">成本占比</td>
                        <td class="detailtitle" width="10%;">采购地域</td>
                        <td class="detailtitle" width="10%;">结算方式</td>
                        <td class="detailtitle" width="10%;">合作年限</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td width="10%;">{{wdsygtable.sn1}}</td>
                        <td width="30%;">{{wdsygtable.gysmc1}}</td>
                        <td width="10%;">{{wdsygtable.spmc1}}</td>
                        <td width="10%;">{{wdsygtable.cge1}}</td>
                        <td width="10%;">{{wdsygtable.cbzb1}}</td>
                        <td width="10%;">{{wdsygtable.cgdy1}}</td>
                        <td width="10%;">{{wdsygtable.jsfs1}}</td>
                        <td width="10%;">{{wdsygtable.hznx1}}</td>
                    </tr>
                    <tr>
                        <td width="10%;">{{wdsygtable.sn2}}</td>
                        <td width="30%;">{{wdsygtable.gysmc2}}</td>
                        <td width="10%;">{{wdsygtable.spmc2}}</td>
                        <td width="10%;">{{wdsygtable.cge2}}</td>
                        <td width="10%;">{{wdsygtable.cbzb2}}</td>
                        <td width="10%;">{{wdsygtable.cgdy2}}</td>
                        <td width="10%;">{{wdsygtable.jsfs2}}</td>
                        <td width="10%;">{{wdsygtable.hznx2}}</td>
                    </tr>
                    <tr>
                        <td width="10%;">{{wdsygtable.sn3}}</td>
                        <td width="30%;">{{wdsygtable.gysmc3}}</td>
                        <td width="10%;">{{wdsygtable.spmc3}}</td>
                        <td width="10%;">{{wdsygtable.cge3}}</td>
                        <td width="10%;">{{wdsygtable.cbzb3}}</td>
                        <td width="10%;">{{wdsygtable.cgdy3}}</td>
                        <td width="10%;">{{wdsygtable.jsfs3}}</td>
                        <td width="10%;">{{wdsygtable.hznx3}}</td>
                    </tr>
                    <tr>
                        <td width="10%;">{{wdsygtable.sn4}}</td>
                        <td width="30%;">{{wdsygtable.gysmc4}}</td>
                        <td width="10%;">{{wdsygtable.spmc4}}</td>
                        <td width="10%;">{{wdsygtable.cge4}}</td>
                        <td width="10%;">{{wdsygtable.cbzb4}}</td>
                        <td width="10%;">{{wdsygtable.cgdy4}}</td>
                        <td width="10%;">{{wdsygtable.jsfs4}}</td>
                        <td width="10%;">{{wdsygtable.hznx4}}</td>
                    </tr>
                    <tr>
                        <td width="10%;">{{wdsygtable.sn5}}</td>
                        <td width="30%;">{{wdsygtable.gysmc5}}</td>
                        <td width="10%;">{{wdsygtable.spmc5}}</td>
                        <td width="10%;">{{wdsygtable.cge5}}</td>
                        <td width="10%;">{{wdsygtable.cbzb5}}</td>
                        <td width="10%;">{{wdsygtable.cgdy5}}</td>
                        <td width="10%;">{{wdsygtable.jsfs5}}</td>
                        <td width="10%;">{{wdsygtable.hznx5}}</td>
                    </tr>
                    </tbody> 
                </table>
                <!-- <div>
                    <div class="credit-handle-list-inside-button">
                        <div :class="handleTitle=='基本信息'?'chose':''" @click="handleTitle='基本信息'">基本信息</div>
                        <div :class="handleTitle=='销售信息'?'chose':''" @click="handleTitle='销售信息'">销售信息</div>
                        <div :class="handleTitle=='采购信息'?'chose':''" @click="handleTitle='采购信息'">采购信息</div>
                        <div :class="handleTitle=='投资信息'?'chose':''" @click="handleTitle='投资信息'" style="opacity:0.3;pointer-events: none;cursor: default;">投资信息</div>
                        <div :class="handleTitle=='融资信息'?'chose':''" @click="handleTitle='融资信息'" style="opacity:0.3;pointer-events: none;cursor: default;">融资信息</div>
                    </div>
                </div>

                <div v-for="(item,index) in content" :key="index" v-show="handleTitle == item.name">
                    <component
                        :key="item.comName"
                        :is="item.comName"
                        :item="item" 
                        :ref="item.comName"
                    ></component>
                </div> -->

            </div>

            <div class="M2-M3-jjhj-Edit">
                <div class="credit-title">
                    <div>
                    <div class="iconImg">
                        <img src="../../../assets/images/credit/credit-Model/经济@2x.png">
                    </div>
                    <span>业务运营</span> 
                    </div>
                    <div>
                    <div class="credit-title-todetail" ></div>
                    </div>
                </div>

                <div class="M2-M3-jjhj-title">1.业务概况</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px">1.1  业务水平</div>

                <m-slider :choseIndex="slider13" :setIndex="13"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">1.2  业务结构</div>

                <m-slider :choseIndex="slider14" :setIndex="14"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">1.3  发展战略</div>

                <m-slider :choseIndex="slider15" :setIndex="15"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea">
                    </el-input>
                </div> 

                <div class="M2-M3-jjhj-title">2.市场和品牌</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px">2.1  市场占有率</div>

                <m-slider :choseIndex.sync="slider16" :setIndex="16"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">2.2  品牌知名度</div>

                <m-slider :choseIndex.sync="slider17" :setIndex="17"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">2.3  市场前景</div>

                <m-slider :choseIndex.sync="slider18" :setIndex="18"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea2">
                    </el-input>
                </div> 

                
                <div class="M2-M3-jjhj-title">3.内部治理</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px">3.1  股权结构</div>

                <m-slider :choseIndex.sync="slider19" :setIndex="19"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">3.2  内控制度</div>

                <m-slider :choseIndex.sync="slider20" :setIndex="20"></m-slider>

                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea3">
                    </el-input>
                </div>  

                <div class="M2-M3-jjhj-title">4.研发能力</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px">4.1  研发体系</div>

                <m-slider :choseIndex.sync="slider21" :setIndex="21"></m-slider>

                <div class="M2-M3-jjhj-title" style="font-size:14px">4.2  研发成果</div>

                <m-slider :choseIndex.sync="slider22" :setIndex="22"></m-slider>

                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input 
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea4">
                    </el-input>
                </div>  
                
            </div> 
        </div>
        <div class="M2-M3-jjhj-buttonList">
            <el-button type="primary" size="small" @click="save">保存</el-button>
            <el-button size="small" @click="routerTo('M2toM3QYSZ')" icon="el-icon-arrow-left">上一页</el-button>
            <el-button size="small" @click="routerTo('M2toM3CWZB')">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Table from '../component/manage/table.vue'
import MSlider from '../component/M2-slider'
import { modularData } from '../credit.js'
export default { 
    name:'M2toM3HYHJ',
    components:{MSlider},
    data() {
      return {
        handleTitle:'基本信息',
        content:modularData,
        tableData: [{
            fl:"主营业务",
            sy:"中药生产及药品贸易",
            code:"",
            c27:"",
        }],
         tableData1: [{
            sn:"1",
            date:"",
            title:"",
            from:"",
        },{
            sn:"2",
            date:"",
            title:"",
            from:"",
        },{
            sn:"3",
            date:"",
            title:"",
            from:"",
        },{
            sn:"4",
            date:"",
            title:"",
            from:"",
        }],
        textarea:'',
        textarea2:'',
        textarea3:'',
        textarea4:'',

        slider13:'',
        slider14:'',
        slider15:'',
        slider16:'',
        slider17:'',
        slider18:'',
        slider19:'',
        slider20:'',
        slider21:'',
        slider22:'',

        ztyytable:{},
        wdxstable:{},
        wdsygtable:{}
      }
    },
    created(){
        this.loadContent()
    }, 
    // watch:{ 
    //     $route(to,from){
    //         for(let i in this.routeTags){ 
    //             if(this.routeTags[i].meta.name == from.meta.name){
    //                 this.$store.commit("delRouteTags", i); 
    //             }
    //         }
    //     } 
    // },  
    mounted(){
        var entName = localStorage.getItem('dupontEntName')
        var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
        // if(localStorage.getItem('entoperates')!=undefined){
        var data = JSON.parse(entData.entoperates)

        this.ztyytable = data.entpoj
        this.wdxstable = data.fivekh
        this.wdsygtable = data.fivegys
        // }
        // if(localStorage.getItem('m2point')!=undefined){
        var arr = entData.m2point?entData.m2point:[]
        this.slider13 = arr[13]?arr[13]:entData.M1Point3
        this.slider14 = arr[14]?arr[14]:entData.M1Point3
        this.slider15 = arr[15]?arr[15]:entData.M1Point3
        this.slider16 = arr[16]?arr[16]:entData.M1Point3
        this.slider17 = arr[17]?arr[17]:entData.M1Point3
        this.slider18 = arr[18]?arr[18]:entData.M1Point3
        this.slider19 = arr[19]?arr[19]:entData.M1Point3
        this.slider20 = arr[20]?arr[20]:entData.M1Point3
        this.slider21 = arr[21]?arr[21]:entData.M1Point3
        this.slider22 = arr[22]?arr[22]:entData.M1Point3
        // // }
        //     this.textarea=localStorage.getItem('ywyyText1')
        //     this.textarea2=localStorage.getItem('ywyyText2')
        //     this.textarea3=localStorage.getItem('ywyyText3')
        //     this.textarea4=localStorage.getItem('ywyyText4')
    },
    methods:{
        save(){
            this.$message({
                message: this.$store.state.saveText,
                type: 'success'
            }); 
            var entName = localStorage.getItem('dupontEntName')
            var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))

            this.$message({
                message: this.$store.state.saveText,
                type: 'success' 
            });
 
            entData.m2point = entData.m2point?entData.m2point:[]
            entData.m2point[13] = entData.m2point[13]?entData.m2point[13]:entData.M1Point3
            entData.m2point[14] = entData.m2point[14]?entData.m2point[14]:entData.M1Point3
            entData.m2point[15] = entData.m2point[15]?entData.m2point[15]:entData.M1Point3
            entData.m2point[16] = entData.m2point[16]?entData.m2point[16]:entData.M1Point3
            entData.m2point[17] = entData.m2point[17]?entData.m2point[17]:entData.M1Point3
            entData.m2point[18] = entData.m2point[18]?entData.m2point[18]:entData.M1Point3
            entData.m2point[19] = entData.m2point[19]?entData.m2point[19]:entData.M1Point3
            entData.m2point[20] = entData.m2point[20]?entData.m2point[20]:entData.M1Point3
            entData.m2point[21] = entData.m2point[21]?entData.m2point[21]:entData.M1Point3
            entData.m2point[22] = entData.m2point[22]?entData.m2point[22]:entData.M1Point3

            entData.ywyyText1 = this.textarea
            entData.ywyyText2 = this.textarea2
            entData.ywyyText3 = this.textarea3
            entData.ywyyText4 = this.textarea4

            localStorage.setItem('dupontEntData'+entName,JSON.stringify(entData))

            // localStorage.setItem('ywyyText1',this.textarea)
            // localStorage.setItem('ywyyText2',this.textarea2)
            // localStorage.setItem('ywyyText3',this.textarea3)
            // localStorage.setItem('ywyyText4',this.textarea4)
        },
        loadContent(){ 
            // for(let i in this.content){
            //     let com = require(`../../credit-search/creditContent.vue`).default;
            //     Vue.component(this.content[i].comName, com);
            // }
        },
        routerTo(name){
            this.save()
            this.$router.push({name:name})
        },
    }
}
</script>
<style lang="less" scoped>
@import url("../../../assets/css/table.css");
@media screen and (max-width: 1300px) {
    .M2-M3-content{
        height: 100%;
    } 
    .M2-M3-jjhj-chart,.M2-M3-jjhj-Edit{
        height: calc(~"100%");
    }
 
}
@media screen and (min-width: 1300px) {
    .M2-M3-content{
         height: calc(~"100vh - 236px");
     }
    .M2-M3-jjhj-chart,.M2-M3-jjhj-Edit{
        overflow-x: hidden;
    //   height: calc(~"100vh - 176px");
    } 
}
.M2-M3-jjhj{
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    >.M2-M3-content{
        // height: calc(~"100vh - 236px");
        display: flex;
        justify-content: space-between;
    }
    // flex-wrap: wrap;
    .tableBox{
        width: 100%;
        overflow-x: auto;
    }
    .M2-M3-jjhj-title{
        font-size: 16px;
        font-weight: bold;
        line-height: 27px;
        color: #30343D;
    }
    .M2-M3-jjhj-chart{
        width: 60%; 
        height: 100%;
        overflow-x: hidden;
        margin-right: 20px;
        position: relative;
        .credit-handle-list-inside-button{
            display: flex;
            margin-top:20px;
            >div{
                width: 20%;
                height: 34px;
                background: #FFFFFF;
                font-size: 14px;
                font-weight: 400;
                color: #2DB7F5;
                text-align: center;
                line-height: 34px;
                margin-right: 10px;
                transition: all ease 0.2s;
                &:hover{
                    cursor: pointer;
                    opacity: 0.6;
                }
            }
            .chose{
                background: #2DB7F5;
                opacity: 1;
                border-radius: 0px 3px 3px 0px;
                color: #fff;
                transition: all ease 0.2s;
                &:hover{
                    cursor: pointer;
                    opacity: 0.6;
                }
            }
        }
        >.M2-M3-jjhj-entBrief{
            width: 100%;
            height: 130px;
            border: 1px solid #dcdcdc;
            background: #fff;
            text-align: center;
            line-height: 130px;
            margin-top: 10px;
        }
        >#zx-chart{
            margin-top:20px;
            width: 100%; 
            height: 615px;
        }
    }
    .M2-M3-jjhj-Edit{ 
        width: 40%;
        height: 100%;
        overflow-x: hidden;
    }
    .M2-M3-jjhj-buttonList{
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
    }
}
</style>